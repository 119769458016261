<template>
  <CModal :show.sync="show">
    <template #header>
      <h6 class="modal-title">Create New ERPC</h6>
    </template>
    <CInput
        :horizontal="true"
        v-model="company.name"
        placeholder="Enter Name"
        label="Name"
        name="name"
    ></CInput>
    <CSelect
             label="Payment"
             horizontal
             :options="paymentOptions"
              placeholder="Select Payment"
             :value.sync="company.payment"
    >
    </CSelect>
  <CRow style="align-items: center; gap:80px">
      <span>Products:</span>
    <v-select style="flex-grow: 1"
        v-model="company.product"
        multiple
        :options="productOptions"
        placeholder="Select option"
    />
  </CRow>
    <template #footer>
      <CButton @click="closeModal" color="primary">Cancel</CButton>
      <CButton @click="emitErpc" color="success">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'ErpcAddModal',
  components: {
    Multiselect,
    vSelect,
  },
  data() {
    return {
      multi:null,
      company: {
        name: '',
        payment: '',
        product: '',
      },
      paymentOptions: [
        { value: 'individual', text: 'Individual' },
        { value: 'corporate', text: 'Corporate' },
      ],
      productOptions: [
        { value: 'AAA', label: 'AAA Response' },
        { value: 'ROADSIDE', label: 'Roadside Assist' },
        { value: 'MEDIVAC', label: 'Medivac' },
        { value: 'SVS', label: 'SVS' },
      ],
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    
    emitErpc() {
      this.$emit('newcompany', this.company);
      this.closeModal(); // If you have a method to close the modal
    },
    closeModal() {
      this.$emit('update:show', false);
    }
  }
};
</script>
